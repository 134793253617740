"use client";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { presignupSchema, UserType } from "@aether/shared/models/presignup";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Building2Icon,
  MailIcon,
  MapPinnedIcon,
  PhoneIcon,
  UserRoundIcon,
} from "lucide-react";
import { presignup } from "@aether/shared/services/presignup";
import { toast } from "sonner";
import { Checkbox } from "@/components/ui/checkbox";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { Textarea } from "../ui/textarea";

export default function PreSignUpForm() {
  const form = useForm<z.infer<typeof presignupSchema>>({
    resolver: zodResolver(presignupSchema),
    defaultValues: {
      fullName: "",
      email: "",
      organization: "",
      phoneNumber: "",
      province: "",
      consent: false,
      role: "photographer",
    },
  });

  async function onSubmit(data: z.infer<typeof presignupSchema>) {
    const presignupPhotographer = await presignup(data);
    if (typeof presignupPhotographer === "string") {
      toast.error(presignupPhotographer);
    } else {
      form.reset();
      toast.success("Thank you for your signing up! Signup success! 🤞");
    }
  }

  const role = form.watch("role");

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className=" w-full flex flex-col gap-4"
      >
        <div>
          <h1 className="text-2xl font-bold font-neutral-600  text-center ">
            Join the wait-list
          </h1>
          <p className="font-light text-sm text-center">
            We are about to launch! <br />
            Be one of the firsts to enjoy new opportunities
          </p>
        </div>
        <hr />

        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel className="flex items-end gap-1">
                <UserRoundIcon className="stroke-1 h-4 w-4" />
                <p>Your Name</p>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel className="flex items-end gap-1">
                <MailIcon className="stroke-1 h-4 w-4" />
                <p>Email Address</p>
              </FormLabel>
              <FormControl>
                <Input {...field} type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="organization"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel className="flex items-end gap-1">
                <Building2Icon className="stroke-1 h-3 w-4" />
                <p>Affiliation/Organization (N/A if freelancer)</p>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel className="flex items-end gap-1">
                  <PhoneIcon className="stroke-1 h-4 w-4" />
                  <p>Contact Number</p>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="province"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel className="flex items-end gap-1">
                  <MapPinnedIcon className="stroke-1 h-4 w-4" />
                  <p>Province</p>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel className="flex items-end gap-1">
                Select Your Role
              </FormLabel>
              <FormControl>
                <div className="flex justify-center gap-4">
                  <Button
                    type="button"
                    variant="secondary"
                    className={cn(
                      `${field.value === "photographer" ? "bg-amber-400 text-white hover:bg-amber-400 hover:text-white" : ""} rounded-full`,
                    )}
                    onClick={() => form.setValue("role", "photographer")}
                  >
                    Photographer
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    className={cn(
                      `${field.value === "organizer" ? "bg-amber-400 text-white hover:bg-amber-400 hover:text-white" : ""} rounded-full`,
                    )}
                    onClick={() => form.setValue("role", "organizer")}
                  >
                    Organizer
                  </Button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="consent"
          render={({ field }) => (
            <FormItem className="">
              <div className="flex items-start space-x-3 space-y-0 rounded-md border p-5 shadow">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormDescription className="leading-snug italic">
                    By clicking subscribe, you read and agree AetherLenz{" "}
                    <Link
                      href="/data-privacy-content"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Data Privacy Form
                    </Link>{" "}
                    and we will reach out to you with the news about AetherLenz.
                  </FormDescription>
                </div>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        {role === "organizer" ? (
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel className="flex items-end gap-1">
                  <p>Leave us a message</p>
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}
        <div className="w-full mt-2">
          <Button className="w-full" disabled={form.formState.isSubmitting}>
            {form.formState.isSubmitting ? (
              <div className="ml-2 border-t-2 border-r-2 h-4 w-4 rounded-full animate-spin" />
            ) : (
              <p>Subscribe</p>
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}
