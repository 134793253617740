import z from "zod";

const userType = ["customer", "organizer", "photographer"] as const;

export const userTypeSchema = z.enum(userType);

export type UserType = z.infer<typeof userTypeSchema>;

export const presignupSchema = z.object({
  fullName: z.string().min(1, "Name is required"),
  email: z.string().email(),
  phoneNumber: z.string().optional(),
  province: z.string().optional(),
  organization: z.string().min(1, "Organization is required"),
  consent: z.boolean().refine((val) => val === true, {
    message: 'You must click "Yes" to accept the data privacy terms.',
  }),
  role: userTypeSchema,
  message: z.string().optional(),
});

export type PresignupSchema = z.infer<typeof presignupSchema>;
